import {
  QuerySalesRapportArgs,
  SalesRapportInput,
  Ticket,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { reactive, ref } from "vue";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { formatNumber } from "@/graphql/utils/utils";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import {
  TICKET_FIELDS,
  TicketStatusEnum,
  getDiscountValues,
} from "@/graphql/ticket/ticket";
import { printRapport } from "@/components/rapport/printer";
import { useI18n } from "vue-i18n";

type SalesRapportData = {
  salesRapport: Ticket[];
};

type SaleRapportRow = Ticket & {
  payed: number;
  cost: number;
  totalNet: number;
  sold: number;
};

const SALES_RAPPORT = gql`
  query SalesRapport($input: SalesRapportInput!) {
    salesRapport(input: $input) {
      ${TICKET_FIELDS}
      sequence {
        id
        number
        terminal {
          id
          name
        }
      }
      movements {
        price
        quantity
      }
      payments {
        ${PAYMENT_RECEIPT_FIELDS}
      }
      customer {
        id
        name
      }
    }
  }
`;

const FILTER = {
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  "sequence.terminal.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
  },
  "sequence.number": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
  },
  number: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  "customer.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  cost: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  payed: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  percentage: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  totalNet: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  sold: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  amount: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  status: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
};

export const useSalesRapport = () => {
  const filteredTotals = reactive({
    payed: 0,
    cost: 0,
    totalNet: 0,
    sold: 0,
    discount: 0,
  });

  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, d, tm } = useI18n();
  const input = reactive<SalesRapportInput>({
    startAt: moment(date).add(-2, "days").toDate(),
    endAt: date,
    sequences: [],
    activityId: activeActivity.value.id,
    period: null,
  });

  const { loading, load, onResult } = useLazyQuery<
    SalesRapportData,
    QuerySalesRapportArgs
  >(SALES_RAPPORT, { input: { ...input } });

  const sales = ref<SaleRapportRow[]>([]);

  const resetFilterValues = () => {
    filteredTotals.payed = 0;
    filteredTotals.cost = 0;
    filteredTotals.discount = 0;
    filteredTotals.sold = 0;
    filteredTotals.totalNet = 0;
  };

  onResult(({ data }) => {
    if (!data.salesRapport.length) return;
    resetFilterValues();
    sales.value = data.salesRapport.map((e) => {
      const { offerAmount, offerPercentage, total, received, isOffered, sold } =
        getDiscountValues(e);
      const sign = e.parentId ? -1 : 1;
      // Put payed credit as payed
      let status = e.status === TicketStatusEnum.CREDIT_PAYED ? 0 : e.status;
      if (isOffered) status = TicketStatusEnum.OFFER;

      e.amount = sign * offerAmount;
      e.percentage = sign * offerPercentage;

      const totalNet = total - offerAmount;

      return {
        ...e,
        number: e.number || 0,
        payed: sign * received,
        status,
        cost: sign * total, //(isOffer ? 0 : totalInt(e.movements) - e.amount),
        totalNet: sign * totalNet,
        sold: sign * sold,
        createdAt: new Date(e.createdAt),
      };
    });
  });

  function refresh() {
    Object.assign(filters.value, FILTER);
  }

  function initData() {
    void load(
      SALES_RAPPORT,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }

  function print() {
    printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="11">
            <h2 class="p-pt-3">${t("rapport.tab0")}</h2>
          </th>
        </tr>
        ${
          !input.sequences.length
            ? `<tr>
          <th colspan="5" style="border-right: unset">
            ${t("rapport.period")}
          </th>
          <th colspan="3" class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th colspan="3" style="border-left: unset">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>`
            : ` <tr>
          <th colspan="11">
            ${t("rapport.ofSequences", { number: input.sequences.length })}
          </th>
        </tr>`
        }
        <tr style="padding-top: 16px; padding-bottom: 16px">
          <th class="p-text-left">${t("date")}</th>
          <th>${t("rapport.ticketNumber")}</th>
          <th>${t("pos.terminal")}</th>
          <th>${t("pos.sequence")}</th>
          <th>${t("rapport.client")}</th>
          <th>${t("rapport.amount")}</th>
          <th>${t("payment.discount")}</th>
          <th>${t("rapport.netTotal")}</th>
          <th>${t("rapport.payed")}</th>
          <th>${t("payment.sold")}</th>
          <th style="text-align: right">${t("product.type")}</th>
        </tr>
      </thead>
      <tbody>
        ${sales.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${d(data.createdAt, "long")}
          </td>
          <td>${formatNumber(data.number)}</td>
          <td>${data.sequence.terminal.name}</td>
          <td>${formatNumber(data.sequence.number)}</td>
          <td>${data.customer?.name || t("customer.default")}</td>
          <td>
            ${formatNumber(data.cost)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.amount)}
            ${activeActivity.value.currencySymbol}
            ${
              data.percentage ? `(${formatNumber(data.percentage, true)}%)` : ""
            }
          </td>
          <td>
            ${formatNumber(data.totalNet)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.payed)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.sold)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td class="p-text-right">
            ${(tm("ticket.status") as any)[data.status]}
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="p-text-left">
            ${t("rapport.total")}
          </th>
          <th>
            ${formatNumber(filteredTotals.cost)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.discount)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.totalNet)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.payed)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.sold)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th></th>
        </tr>
      </tfoot>
    </table>`);
  }

  function onFilter(event: any) {
    resetFilterValues();
    (event.filteredValue as SaleRapportRow[]).forEach((ticket) => {
      // sign * (isOffer ? 0 : totalInt(ticket.movements) - ticket.amount);
      // const sign = ticket.parentId ? -1 : 1;
      // const payed = paymentSum(ticket.payments);
      // const isOffer = payed === 0 && ticket.status === 0;
      filteredTotals.payed += ticket.payed;
      filteredTotals.cost += ticket.cost;
      filteredTotals.discount += ticket.amount;
      filteredTotals.sold += ticket.sold;
      filteredTotals.totalNet += ticket.totalNet;
    });
  }

  return {
    onFilter,
    filteredTotals,
    loading,
    sales,
    input,
    initData,
    refresh,
    filters,
    print,
    formatNumber,
  };
};
