
import { defineComponent, reactive, defineAsyncComponent } from "vue";
import { useSalesRapport } from "@/graphql/rapport/sales.rapport";
import RapportFilter from "@/components/rapport/RapportFilter.vue";
import SequencesSelect from "@/components/sequence/SequencesSelect.vue";
import { statusColors } from "@/components/rapport/printer";
import { activeActivity } from "@/plugins/i18n";
import { Ticket } from "@/graphql/types";
import { CONSTANTS, DBDateLong, formatDate } from "@/graphql/utils/utils";

export default defineComponent({
  name: "SalesRapport",
  components: {
    TicketDetail: defineAsyncComponent(
      () => import("@/components/ticket/TicketDetail.vue")
    ),
    RapportFilter,
    SequencesSelect,
  },
  setup() {
    const details = reactive({
      show: false,
      ticket: null,
    });
    return {
      ...useSalesRapport(),
      statusColors,
      details,
      openDetails: (ticket: Ticket) => {
        details.ticket = ticket;
        details.show = true;
      },
      currencySymbol: activeActivity.value.currencySymbol,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
      formatDate,
      DBDateLong,
    };
  },
  created() {
    this.initData();
  },
});
