import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-78450401"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "field-radiobutton p-mt-2"
}
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_SequencesSelect = _resolveComponent("SequencesSelect")!
  const _component_RapportFilter = _resolveComponent("RapportFilter")!
  const _component_TicketDetail = _resolveComponent("TicketDetail")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RapportFilter, {
      "start-at": _ctx.input.startAt,
      "onUpdate:startAt": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.input.startAt) = $event)),
      "end-at": _ctx.input.endAt,
      "onUpdate:endAt": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.input.endAt) = $event)),
      period: _ctx.input.period,
      "onUpdate:period": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.input.period) = $event)),
      loading: _ctx.loading,
      onValidate: _cache[7] || (_cache[7] = ($event: any) => (_ctx.initData())),
      label: "none",
      onEmptySequence: _cache[8] || (_cache[8] = ($event: any) => (_ctx.input.sequences.length = 0)),
      tooltip: _ctx.$t('ticket.totalTooltip')
    }, {
      table: _withCtx(() => [
        _createVNode(_component_DataTable, {
          dataKey: "id",
          rowHover: false,
          rows: 20,
          "rows-per-page-options": _ctx.rowsPerPageOptions,
          value: _ctx.sales,
          onFilter: _ctx.onFilter,
          filters: _ctx.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
          filterDisplay: "menu",
          showGridlines: "",
          paginator: true,
          class: "p-datatable-sm",
          responsiveLayout: "scroll",
          scrollable: "",
          "scroll-height": "flex",
          style: {"height":"calc(100vh - 194px)"}
        }, {
          header: _withCtx(() => [
            _createVNode(_component_Button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refresh())),
              label: _ctx.$t('clear'),
              icon: "pi pi-refresh"
            }, null, 8, ["label"]),
            _createVNode(_component_Button, {
              disabled: !_ctx.sales.length,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.print())),
              label: _ctx.$t('pos.print'),
              icon: "pi pi-print"
            }, null, 8, ["disabled", "label"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              "show-add-button": false,
              showFilterOperator: false,
              "filter-field": "createdAt",
              field: "createdAt",
              header: _ctx.$t('date'),
              dataType: "date",
              sortable: true,
              "sort-field": "createdAt",
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.$d(data.createdAt, "long")), 1)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_Calendar, {
                  manualInput: false,
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  dateFormat: _ctx.$t('format.range'),
                  placeholder: _ctx.$t('format.range'),
                  style: {"min-width":"200px"},
                  "show-button-bar": ""
                }, {
                  header: _withCtx(() => []),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "dateFormat", "placeholder"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "number",
              header: _ctx.$t('rapport.ticketNumber'),
              sortable: true,
              "sort-field": "number",
              "filter-field": "number",
              dataType: "numeric",
              "show-add-button": false,
              showFilterOperator: false,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                (data.number)
                  ? (_openBlock(), _createBlock(_component_NumberFormatter, {
                      key: 0,
                      value: data.number
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputNumber, {
                  showButtons: "",
                  buttonLayout: "horizontal",
                  step: 1,
                  decrementButtonClass: "p-button-danger",
                  incrementButtonClass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  min: 0,
                  "allow-empty": true
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "sequence.terminal.name",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "filter-field": "sequence.terminal.name",
              "sort-field": "sequence.terminal.name",
              header: _ctx.$t('pos.terminal'),
              "show-apply-button": false,
              "show-clear-button": false,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  onKeyup: ($event: any) => (filterCallback()),
                  placeholder: _ctx.$t('pos.terminal')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "placeholder"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "sequence.number",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "filter-field": "sequence.number",
              "sort-field": "sequence.number",
              header: "Séq",
              "show-apply-button": false,
              "show-clear-button": false,
              "header-style": { 'max-width': '85px' },
              "body-style": { 'max-width': '85px' },
              "footer-style": { 'max-width': '85px' }
            }, {
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  onKeyup: ($event: any) => (filterCallback()),
                  placeholder: _ctx.$t('pos.sequence')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "customer.name",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "filter-field": "customer.name",
              "sort-field": "customer.name",
              header: _ctx.$t('rapport.client'),
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "cost",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "sort-field": "cost",
              header: _ctx.$t('rapport.amount'),
              dataType: "numeric",
              footer: `${_ctx.formatNumber(_ctx.filteredTotals.cost)} ${_ctx.currencySymbol}`,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_NumberFormatter, {
                  value: data.cost
                }, null, 8, ["value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.currencySymbol), 1)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputNumber, {
                  showButtons: "",
                  buttonLayout: "horizontal",
                  step: 1,
                  decrementButtonClass: "p-button-danger",
                  incrementButtonClass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  "max-fraction-digits": 20,
                  min: 0
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header", "footer"]),
            _createVNode(_component_Column, {
              field: "amount",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "sort-field": "amount",
              header: _ctx.$t('payment.discount'),
              dataType: "numeric",
              footer: `${_ctx.formatNumber(_ctx.filteredTotals.discount)} ${_ctx.currencySymbol}`,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_NumberFormatter, {
                  value: data.amount
                }, null, 8, ["value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.currencySymbol) + " ", 1),
                (data.percentage)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" ("),
                      _createVNode(_component_NumberFormatter, {
                        value: data.percentage,
                        discount: ""
                      }, null, 8, ["value"]),
                      _createTextVNode("%) ")
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputNumber, {
                  showButtons: "",
                  buttonLayout: "horizontal",
                  step: 1,
                  decrementButtonClass: "p-button-danger",
                  incrementButtonClass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  "max-fraction-digits": 20,
                  min: 0
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header", "footer"]),
            _createVNode(_component_Column, {
              field: "totalNet",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "sort-field": "totalNet",
              header: _ctx.$t('rapport.netTotal'),
              dataType: "numeric",
              footer: `${_ctx.formatNumber(_ctx.filteredTotals.totalNet)} ${_ctx.currencySymbol}`,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_NumberFormatter, {
                  value: data.totalNet
                }, null, 8, ["value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.currencySymbol), 1)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputNumber, {
                  showButtons: "",
                  buttonLayout: "horizontal",
                  step: 1,
                  decrementButtonClass: "p-button-danger",
                  incrementButtonClass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  "max-fraction-digits": 20,
                  min: 0
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header", "footer"]),
            _createVNode(_component_Column, {
              field: "payed",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "filter-field": "payed",
              "sort-field": "payed",
              header: _ctx.$t('rapport.payed'),
              dataType: "numeric",
              footer: `${_ctx.formatNumber(_ctx.filteredTotals.payed)} ${_ctx.currencySymbol}`,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_NumberFormatter, {
                  value: data.payed
                }, null, 8, ["value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.currencySymbol), 1)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputNumber, {
                  showButtons: "",
                  buttonLayout: "horizontal",
                  step: 1,
                  decrementButtonClass: "p-button-danger",
                  incrementButtonClass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  "max-fraction-digits": 20,
                  min: 0
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header", "footer"]),
            _createVNode(_component_Column, {
              field: "sold",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "sort-field": "sold",
              header: _ctx.$t('payment.sold'),
              dataType: "numeric",
              footer: `${_ctx.formatNumber(_ctx.filteredTotals.sold)} ${_ctx.currencySymbol}`,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_NumberFormatter, {
                  value: data.sold
                }, null, 8, ["value"]),
                _createTextVNode(" " + _toDisplayString(_ctx.currencySymbol), 1)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputNumber, {
                  showButtons: "",
                  buttonLayout: "horizontal",
                  step: 1,
                  decrementButtonClass: "p-button-danger",
                  incrementButtonClass: "p-button-success",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  "max-fraction-digits": 20,
                  min: 0
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-button-sm",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  label: _ctx.$t('search'),
                  type: "button",
                  icon: "pi pi-search",
                  class: "p-button-sm",
                  onClick: ($event: any) => (filterCallback())
                }, null, 8, ["label", "onClick"])
              ]),
              _: 1
            }, 8, ["header", "footer"]),
            _createVNode(_component_Column, {
              field: "status",
              "show-add-button": false,
              showFilterOperator: false,
              sortable: true,
              "sort-field": "status",
              header: _ctx.$t('product.type'),
              dataType: "numeric",
              "show-filter-match-modes": false,
              "header-style": { 'min-width': '120px' },
              "body-style": { 'min-width': '120px' },
              "footer-style": { 'min-width': '120px' }
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString((_ctx.$tm("ticket.status") as any)[data.status]), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('ticket.status'), (type, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (index !== 3)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createVNode(_component_Checkbox, {
                            id: `ticket-status-${index}`,
                            name: "ticket-status",
                            value: index,
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                            onChange: ($event: any) => (filterCallback())
                          }, null, 8, ["id", "value", "modelValue", "onUpdate:modelValue", "onChange"]),
                          _createElementVNode("label", {
                            class: "p-ml-2",
                            for: `ticket-status-${index}`
                          }, _toDisplayString(type), 9, _hoisted_2)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  icon: "pi pi-times",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-warning p-mr-1",
                  label: _ctx.$t('prime.clear')
                }, null, 8, ["onClick", "label"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  icon: "pi pi-search",
                  onClick: ($event: any) => (filterCallback()),
                  class: "p-button-success",
                  label: _ctx.$t('search')
                }, null, 8, ["onClick", "label"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              "header-style": { 'max-width': '75px' },
              "body-style": { 'max-width': '75px' },
              "footer-style": { 'max-width': '75px' },
              header: _ctx.$t('controls')
            }, {
              body: _withCtx(({ data }) => [
                _withDirectives(_createVNode(_component_Tag, {
                  onClick: ($event: any) => (_ctx.openDetails(data)),
                  style: _normalizeStyle(`background:${_ctx.statusColors[data.status]}`),
                  icon: "pi pi-eye",
                  class: "cursor-pointer p-ripple"
                }, null, 8, ["onClick", "style"]), [
                  [_directive_ripple]
                ])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }, 8, ["rows-per-page-options", "value", "onFilter", "filters"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_SequencesSelect, {
          modelValue: _ctx.input.sequences,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.input.sequences) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["start-at", "end-at", "period", "loading", "tooltip"]),
    (_ctx.details.show)
      ? (_openBlock(), _createBlock(_component_TicketDetail, {
          key: 0,
          ticket: _ctx.details.ticket,
          visible: _ctx.details.show,
          "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.details.show) = $event)),
          status: _ctx.statusColors
        }, null, 8, ["ticket", "visible", "status"]))
      : _createCommentVNode("", true)
  ], 64))
}